<template>
  <div>
    <CRow>
      <CCol col="12">
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12">
        <CSelect
          :options="storeSelectOptions"
          :disabled="!document.draft"
          label="Со склада"
          :value.sync="document.fromStoreId"
        />
      </CCol>
      <CCol col="12">
        <CSelect
          :options="storeSelectOptions"
          disabled
          label="На склад"
          :value.sync="document.toStoreId"
        />
      </CCol>
      <CCol col="12">
        <CInput
          disabled
          label="Создан"
          :value="document.externalCreatedAt"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'InternalOrder',
  data() {
    return {
      loading: false,
      stores: [],
    }
  },
  props: {
    document: Object
  },
  computed: {
    storeSelectOptions() {
      return this.stores.map(el => ({label: el.name, value: el.id})).concat({label: 'Не выбрано', value: null});
    }
  },
  mounted() {
    this.loadStores();
  },
  methods: {
    async loadStores() {
      try {
        const {data} = await this.$axios.get('api/stores');
        console.log(data, 'stores');
        this.stores = data;
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>
