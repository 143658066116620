<template>
  <div v-if="document">
    <CCard body-wrapper :style="{minHeight: 250}">
      <div class="d-flex align-items-center">
        <CButton class="mr-2" color="secondary" @click="$router.back()" size="sm">
          Закрыть
        </CButton>
        <CButton v-if="hasChanges" class="mx-2" color="primary" size="sm">
          Сохранить
        </CButton>
        <document-actions :document-id="document.id" :actions="availableActions" />
        <div class="flex-grow-1" />
        <last-change :changes="document.documentChanges"/>
      </div>
      <div class="d-flex">
        <div class="font-3xl flex-grow-1">{{$t(`document.types.${document.type}`)}} {{document.number}}</div>
      </div>

      <component :is="documentType" :document="document" />
      <field-value v-for="fieldValue in document.fieldValues" :key="fieldValue.id" :field-value="fieldValue" />
    </CCard>

    <document-lines :lines="document && document.lines" :loading="!document" />
    <related-documents :list="document && document.relatedDocuments" :loading="!document"/>
    <pipelines-list v-if="!!document" :document-id="document.id" />
  </div>
</template>

<script>
import Forms from './forms';
import FieldValue from './fields';
import LoadingButton from '@/components/loading-button';
import DocumentActions from './components/document-actions';
import DocumentLines from './components/lines';
import RelatedDocuments from './components/related-documents';
import LastChange from './components/last-change';
import PipelinesList from './components/pipelines-list';

export default {
  name: 'Info',
  data() {
    return {
      loading: false,
      restartInProgress: false,
      saving: false,
      document: null,
      availableActions: [],
      documentForm: {}
    }
  },
  components: {
    ...Forms,
    FieldValue,
    LoadingButton,
    DocumentActions,
    DocumentLines,
    RelatedDocuments,
    LastChange,
    PipelinesList
  },
  watch: {
    '$route.params': function() {
      this.document = null;
      this.load();
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    documentId() {
      return this.$route.params.id
    },
    documentType() {
      if (this.document) {
        return this.document.type.split('::')[1]
      }
      return null;
    },
    hasChanges() {
      return Object.keys(this.documentForm).length > 0;
    }
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        const {data} = await this.$axios.request({url: `api/documents/${this.documentId}`});
        this.document = data;
        this.loadAvailableActions();
      } catch(e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async loadAvailableActions() {
      const {data} = await this.$axios.request({url: 'api/documents/available_actions', params: {type: this.document.type}});
      this.availableActions = data;
    },
    async restart() {
      try {
        this.restartInProgress = true;
        const {data} = await this.$axios.post(`api/documents/batch_restart`, {ids: [this.documentId]});
        this.document = {...this.document, ...data[0]};
      } catch (e) {
        console.log(e)
      } finally {
        this.restartInProcess = false;
      }
    },
    async save() {
      try {
        this.saving = true;
      } finally {
        this.saving = false;
      }
    },
  }
}
</script>

<style lang="scss">
  .divider {
    height: .5px;
    margin: 12px 0px;
    background-color: rgba(128, 128, 128, .5);
    width: 100%;
  }
</style>
