<template>
  <CModal :show="show" v-on:update:show="close">
  </CModal>
</template>

<script>
export default {
  name: 'ChangesDialog',
  props: {
    changes: Array
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    }
  }
}
</script>
