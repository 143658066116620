<template>
  <CCard body-wrapper class="mt-4" :style="{minHeight: 250}">
    <CElementCover v-if="loading" :opacity="0.8" />
    <div class="font-3xl">Позиции</div>
    <CDataTable
      :items="lines"
      :fields="lineFields"
      hover
      striped
    >
      <template #no-items-view>
        <div class="text-center">
          Нет позиций
        </div>
      </template>
      <template #number="{index}">
        <td class="py-2 middle-align">
          {{ index + 1 }}
        </td>
      </template>
      <template #actions>
        <td class="py-2">
        </td>
      </template>
    </CDataTable>
  </CCard>
</template>

<script>

export default {
  name: 'DocumentLines',
  components: {
  },
  props: {
    lines: Array,
    loading: Boolean
  },
  computed: {
    lineFields() {
      return [
        {key: 'number', label: '№'},
        {key: 'sku', label: 'Артикул'},
        {key: 'quantity', label: 'Кол-во'},
        {key: 'price', label: 'Цена', _classes: ['font-weight-bold', 'align-middle']},
        {key: 'actions', label: ''}
      ]
    },
  },
}
</script>
