<template>
  <component :is="componentName" :field-value="fieldValue" />
</template>

<script>
import LinkField from './link';
import StringField from './string';

export default {
  name: 'FieldValue',
  props: {
    fieldValue: Object,
  },
  components: {
    LinkField,
    StringField
  },
  computed: {
    componentName() {
      switch(this.fieldValue.type) {
        case 'FieldValue::Link':
          return 'link-field';
        default:
          return 'string-field';
      }
    }
  }
}
</script>
