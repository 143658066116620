<template>
  <CRow>
    <CCol col="12">
      <CInput
        disabled
        :label="fieldValue.title"
        :value="fieldValue.value"
      />
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'StringField',
  props: {
    fieldValue: Object
  }
}
</script>
