<template>
  <div class="d-flex px-2 align-items-center">
    <div class="mr-2" v-if="lastChange">
      <CLink
        href="#"
        class="font-sm"
        @click.prevent="openModal"
      >
        Последнее изменение
      </CLink>
      <div class="">
        {{ lastChange.displayName }}
      </div>
    </div>
    <div>
      <CIcon name="cil-user" size="lg" />
    </div>
    <changes-dialog ref="changesDialog" />
  </div>
</template>

<script>
import ChangesDialog from './changes-dialog';
export default {
  name: 'LastDocumentChange',
  components: {
    ChangesDialog
  },
  props: {
    changes: []
  },
  computed: {
    lastChange() {
      return this.changes[0]
    }
  },
  methods: {
    openModal() {
      // this.$refs.changesDialog.open()
    }
  }
}
</script>
