<template>
  <CCard body-wrapper class="mt-4" :style="{minHeight: 250}">
    <CElementCover v-if="loading" :opacity="0.8" />
    <h2>Сценарии</h2>
    <CDataTable
      :items="list"
      :fields="fields"
      hover
      striped
    >
      <template #no-items-view>
        <div class="text-center">
          Нет запущенных сценариев
        </div>
      </template>
      <template #status="{item}">
        <td class="align-middle">
          <CBadge :color="calculateProps(item)">
            {{ item.statusText }}
          </CBadge>
        </td>
      </template>
      <template #steps="{item}">
        <td class="align-middle">
          <div class="d-flex align-items-center step__container">
            <CDropdown
              v-for="step in item.steps"
              :key="step.id"
              class="mr-1 step"
            >
              <template #toggler>
                <div
                  class="step__round"
                  :class="{success: step.status == 'ready', error: step.status == 'error', pending: step.status == 'pending'}"
                >
                  <CIcon name="cil-check" v-if="step.status == 'ready'" />
                  <CSpinner color="info" size="sm" v-else-if="step.status == 'pending'" />
                  <CIcon name="cil-check" v-else-if="step.status == 'error'" />
                  <CIcon name="cil-media-pause" v-else />
                </div>
              </template>
              <CDropdownItem class="align-middle">
                <div>{{ step.displayName }}</div>
              </CDropdownItem>
            </CDropdown>
          </div>
        </td>
      </template>
      <template #actions="{ item }">
        <td class="align-middle text-right">
          <CButton
            color="secondary"
            title="Подробнее"
            :to="{ name: 'admin.pipelines.info', params: { id: item.id } }"
          >
            <CIcon name="cilPencil" />
          </CButton>
        </td>
      </template>
    </CDataTable>
  </CCard>
</template>
<script>
export default {
  name: 'PipelinesList',
  props: {
    documentId: Number
  },
  data() {
    return {
      list: [],
      loading: true
    }
  },
  computed: {
    fields() {
      return [
        {key: 'id', label: '№', _classes: ['align-middle']},
        {key: 'displayName', label: 'Название', _classes: ['align-middle']},
        {key: 'status', label: 'Статус'},
        {key: 'steps', label: 'Шаги'},
        {key: 'actions', label: ''}
      ]
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    calculateProps(item) {
      if (item.ready) {
        return 'success'
      }

      if (item.status == 'error') {
        return 'danger'
      }

      return 'info'
    },
    async load() {
      try {
        this.loading = true
        const {data} = await this.$axios.request({url: `api/documents/${this.documentId}/pipelines`});
        this.list = data;
        this.loading = false
      } catch(e) {
        console.error(e);
      }
    },
  }
}
</script>

<style lang="scss">
  .step__container .step:not(:last-child):after {
    content: '';
    width: 7px;
    position: absolute;
    right: -7px;
    top: 11px;
    border-bottom: 2px solid var(--border-color, #dbdbdb);
  }

  .step {
    cursor: pointer;

    &__container {
      position: relative;
    }

    &__line {
      position: absolute;
      left: 12px;
      right: 12px;
      top: 11px;
      height: 2px;
      background-color: rgba(128, 128, 128, .7);
    }

    &__round {
      width: 24px;
      height: 24px;
      padding: 4px;
      border-radius: 12px;
      border-width: 1px;
      border-style: solid;
      border-color: black;
      background-color: white;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;

      &.success {
        border-color: #108548;
        color: #108548;

        &:hover {
          color: #217645;
        }
      }

      &.error {
        border-color: #dd2b0e;
        color: #dd2b0e;

        &:hover {
          color: #c91c00;
        }
      }

      &.pending {
        border-color: #39f;
        color: #39f;

        &:hover {
          color: #046dd5;
        }
      }

      &:hover {
        background-color: #ededed;
      }
    }
  }
</style>
