<template>
  <CCard body-wrapper class="mt-4" :style="{minHeight: 250}">
    <CElementCover v-if="loading" :opacity="0.8" />
    <h2>Связанные документы</h2>
    <CDataTable
      :items="list"
      :fields="relatedFields"
      hover
      striped
    >
      <template #no-items-view>
        <div class="text-center">
          Нет связанных документов
        </div>
      </template>
      <template #type={item}>
        <td class="py-2 middle-align">
          {{ $t(`document.types.${item.type}`) }}
        </td>
      </template>
      <template #number="{item}">
        <td class="py-2 middle-align">
          <router-link :to="{ name: 'admin.documents.info', params: { id: item.id } }">{{ item.number }}</router-link>
        </td>
      </template>
      <template #actions>
        <td class="py-2">
        </td>
      </template>
    </CDataTable>
  </CCard>
</template>

<script>
export default {
  name: 'RelatedDocuments',
  props: {
    list: Array,
    loading: Boolean
  },
  computed: {
    relatedFields() {
      return [
        {key: 'number', label: '№'},
        {key: 'type', label: 'Тип'},
        {key: 'amount', label: 'Сумма', _classes: ['font-weight-bold', 'align-middle']},
        {key: 'actions', label: ''}
      ]
    }
  },
}
</script>
