<template>
  <div>
    <CRow>
      <CCol col="12">
        <CInput
          disabled
          label="Склад"
          :value="document.toStore && document.toStore.name"
        />
      </CCol>
      <CCol col="12">
        <CInput
          disabled
          label="Создан"
          :value="document.externalCreatedAt"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'Enter',
  data() {
    return {
      loading: false,
    }
  },
  props: {
    document: Object
  },
  methods: {
  }
}
</script>
