<template>
  <CDropdown
    class="mx-2"
    color="primary"
    size="sm"
    toggler-text="Действия"
  >
    <CDropdownItem
      v-for="action in actions"
      :key="action.handle"
      :disabled="restarts[action.handle]"
      @click="restartScenario(action.handle)"
    >
      <CSpinner
        v-if="restarts[action.handle]"
        class="custom-spinner"
        color="info"
      />
      Запустить {{ action.label }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  name: 'GroupActions',
  props: {
    documentId: Number,
    actions: Array,
  },
  data() {
    return {
      restarts: {}
    }
  },
  methods: {
    async restartScenario(handle) {
      try {
        this.restarts[handle] = true;
        const {data} = await this.$axios.post('/api/documents/batch_restart', {ids: [this.documentId], scenario: handle});
        this.$emit('restart:end', data);
        await Swal.fire(
          'Успешно',
          'Документы отправлены на обработку',
          'success',
        );
      } catch(e) {
        await Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Что-то пошло не так'
        })
      } finally {
        this.restarts[handle] = false;
      }
    }
  }
}
</script>
