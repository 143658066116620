<template>
  <CRow>
    <CCol col="12">
      <CInput
        disabled
        :label="fieldValue.title"
        :value="fieldValue.value"
      >
        <template #append>
          <CButton color="primary" size="sm" @click="open">
            <CIcon name="cil-external-link" />
          </CButton>
        </template>
      </CInput>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'LinkField',
  props: {
    fieldValue: Object
  },
  methods: {
    open() {
      window.open(this.fieldValue.value, '_blank').focus()
    }
  }
}
</script>
